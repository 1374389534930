import "bootstrap/dist/css/bootstrap.css";
import { Card, Table } from "react-bootstrap";

const generateTasks = (tasks) => {
    return (
        <Card>
            <Card.Header>Zadania serwisowe</Card.Header>
            <Card.Body>
                <Table size="sm">
                    <tbody>
                        {tasks.map(task => (
                            <tr className="ml-2 mr-2" key={task}><td>{task}</td></tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default generateTasks