import React, {Component} from 'react';
import "bootstrap/dist/css/bootstrap.css";
import {Card, Button} from "react-bootstrap";

class UserCard extends Component {
    state = {
        date_add: null,
        ticket_id: null,
        status: null,
        producer: null,
        model: null,
        final_price: null,
        is_staff: null,
    }

    triggerClick = () => {
        this.handleClick(this.state)
    }

    constructor(props) {
        super(props)
        let prettyDate = new Date(props.date_add).toLocaleDateString()
        this.state = {
            date_add: prettyDate,
            ticket_id: props.ticket_id,
            status: props.status,
            producer: props.producer,
            model: props.model,
            final_price: props.final_price,
            is_staff: props.is_staff,
        }

        this.handleClick = props.on_click
    }

    render() {
        return (
            <Card className="bg-dark text-white" style={{ margin: '20px 0 20px 0' }}>
                <Card.Body>
                    <Card.Title>&#128187; Zgłoszenie nr {this.state.ticket_id} z dnia {this.state.date_add}</Card.Title>
                    <Card.Text>
                        Urządzenie: {this.state.producer} {this.state.model}
                        <br/>Status: {this.state.status}
                        <br/>{this.state.final_price ? `Do zapłaty: ${Number(this.state.final_price).toFixed(2).replace(".", ",")} zł` : ""}
                    </Card.Text>
                    <Button onClick={this.triggerClick}>{this.state.is_staff ? `Edytuj` : `Szczegóły`}</Button>
                </Card.Body>
            </Card>
        );
    };
}

export default UserCard;