import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Alert } from "react-bootstrap";

const generateAlert = (message, variant = "danger") => {
    return (
        <Alert variant={variant} className="mt-2">
            {message}
        </Alert>
    )
}

export default generateAlert