import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Card } from "react-bootstrap";

class TicketClient extends Component {
    constructor(props) {
        super(props)
        this.client = props.client
        this.phone = props.phone
    }

    render() {
        return (
            <Card className="mt-2">
                <Card.Header>Klient</Card.Header>
                <Card.Body>
                    {this.client.first_name} {this.client.last_name}
                    <br />
                    Tel. {this.phone}
                    <br />
                    E-mail: {this.client.email}
                </Card.Body>
            </Card>
        )
    }
}

export default TicketClient