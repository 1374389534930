import { useState, useEffect } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css";
import { Container, Card, Button, Form, Alert, Row, Col } from "react-bootstrap";
import ServiceNavbar from "../Utils/service_navbar";
import { forEach } from "react-bootstrap/ElementChildren";
import jwt_decode from "jwt-decode";

import { Mutex } from 'async-mutex';
import axiosRefreshToken from "../../functions/axiosRefreshToken"

const TicketForm = () => {
    const navigate = useNavigate()
    const mutex = new Mutex();

    const axiosJWT = axios.create({
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("access")}`
        }
    })
    axiosJWT.interceptors.request.use(async function (config) {
        const release = await mutex.acquire()
        try {
            return axiosRefreshToken(config, navigate)
        }
        finally {
            release()
        }
    }, function (error) {
        return Promise.reject(error);
    })

    const [data, setData] = useState({
        producer: "",
        model: "",
        description: "",
        phone: "",
        tasks: [],
        price_evaluation_min: 0,
        price_evaluation_max: 0,
        delivery: "0",
        first_name: "",
        last_name: "",
        street: "",
        building_number: "",
        apartment_number: "",
        postal_code: "",
        city: ""
    })

    const [errors, setErrors] = useState("")
    const [alerts, setAlerts] = useState({
        model: "",
        phone: "",
        first_name: "",
        last_name: "",
        street: "",
        building_number: "",
        apartment_number: "",
        postal_code: "",
        city: ""
    })

    const [user, setUser] = useState()

    const validateField = (name, value) => {
        let validators = {
            model: /^([0-9A-ZĄĘŚĆŻŹÓŁŃa-ząęśćżźółń\-.()+ ]+)?$/,
            phone: /^([+0]([0-9]{2,3})[ ]?)?[0-9]{3}[ ]?[0-9]{3}[ ]?[0-9]{3}$|^[0-9]{2}[ ]?[0-9]{3}[ ]?[0-9]{2}[ ]?[0-9]{2}$/i,
            first_name: /^[A-Z][a-ząęśćżźółń]+$/,
            last_name: /^[A-Z][A-Za-ząęśćżźółń-]+$/,
            street: /^[0-9A-ZĄĘŚŹŻŹÓŁŃ][0-9A-ZĄĘŚŹŻŹÓŁŃa-ząęśćżźółń\-. ]+$/,
            building_number: /^[0-9A-Za-z]+$/,
            apartment_number: /^([0-9A-Za-z]+)?$/,
            postal_code: /^[0-9]{2}[-]?[0-9]{3}$/,
            city: /^[0-9A-ZĄĘŚĆŻŹÓŁŃ][0-9A-ZĄĘŚĆŻŹÓŁŃa-ząęśćżźółń\-. ]+$/
        }
        if (validators[name]) {
            return validators[name].test(value)
        }
    }

    const dataValid = () => {
        return errors.model || errors.phone || errors.street || errors.building_number || errors.apartment_number || errors.postal_code || errors.city
    }

    useEffect(() => {
        getProducers()
        getTasks()
        getUser()

        localStorage.setItem("price_evaluation_min", "0")
        localStorage.setItem("price_evaluation_max", "0")
    }, []);

    const [evaluationData, setEvaluationData] = useState()
    const [producer, setProducer] = useState()

    const generateEvaluation = () => {
        localStorage.setItem("price_evaluation_min", data.price_evaluation_min)
        localStorage.setItem("price_evaluation_max", data.price_evaluation_max)
        return <Alert className="mt-2" variant="info">
            {data.price_evaluation_max !== 0 ? `Wycena wstępna: ${data.price_evaluation_min}-${data.price_evaluation_max} zł` : ""}
        </Alert>
    }
    const [taskData, setTaskData] = useState()

    const generateTaskCheckboxes = (taskData) => {
        return <Form.Group>
            {taskData.map(task => (
                <Form.Check
                    key={task.id}
                    type="checkbox"
                    label={task.name + " (" + Number(task.min_price) + "-" + Number(task.max_price) + " zł)"}
                    value={task.id}
                    id={task.id}
                    min_price={task.min_price}
                    max_price={task.max_price}
                    onChange={handleTaskCheckbox}
                />
            ))}
        </Form.Group>
    }

    const getTasks = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/tasks`)
            .then(res => {
                const tasks = res.data
                setTaskData(generateTaskCheckboxes(tasks))
            })
    }

    const getUser = () => {
        axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/user`)
            .then(res => {
                const user = res.data
                setUser(user)
                setData({...data, first_name: user.first_name, last_name: user.last_name})
            })
    }

    const [producerData, setProducerData] = useState()

    const generateProducerSelect = (producerData) => {
        return <Form.Select name="producer" onChange={handleProducerSelect} required>
            <option key="producer-select" value="">Wybierz producenta</option>
            {producerData.map(producer => (
                <option key={producer.id} value={producer.id}>{producer.name}</option>
            ))}
        </Form.Select>
    }

    const getProducers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/producers`)
            .then(res => {
                const producers = res.data
                setProducerData(generateProducerSelect(producers))
            })
    }

    const [error, setError] = useState("")

    const handleChange = ({ currentTarget: input }) => {
        setErrors({ ...errors, [input.name]: !validateField(input.name, input.value) })
        setData({ ...data, [input.name]: input.value })
    }

    const handleDelivery = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value })
        if (input.value === "0") {
            setErrors({
                ...errors,
                first_name: undefined,
                last_name: undefined,
                street: undefined,
                building_number: undefined,
                apartment_number: undefined,
                postal_code: undefined,
                city: undefined
            })
        }
        else {
            setErrors({
                ...errors,
                first_name: !validateField("street", data.first_name),
                last_name: !validateField("street", data.last_name),
                street: !validateField("street", data.street),
                building_number: !validateField("building_number", data.building_number),
                apartment_number: !validateField("apartment_number", data.apartment_number),
                postal_code: !validateField("postal_code", data.postal_code),
                city: !validateField("city", data.city)
            })
        }
    }

    const handleProducerSelect = ({ currentTarget: input }) => {
        setProducer(input.value)
    }

    const handleTaskCheckbox = ({ currentTarget: input }) => {
        if (input.checked) {
            data.tasks.push(input.value)
            data.price_evaluation_min += Number(input.getAttribute("min_price"))
            data.price_evaluation_max += Number(input.getAttribute("max_price"))
        } else {
            data.tasks.splice(data.tasks.indexOf(input.value), 1)
            data.price_evaluation_min -= Number(input.getAttribute("min_price"))
            data.price_evaluation_max -= Number(input.getAttribute("max_price"))
        }
        if (data.tasks.length > 0) {
            setEvaluationData(generateEvaluation)
        }
        else {
            setEvaluationData("")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(false)
        if (data.tasks.length === 0 && data.description === "") {
            setError("Należy wpisać opis słowny lub wybrać przynajmniej jedno zadanie.")
        }
        else {
            try {
                const url = `${process.env.REACT_APP_API_URL}/api/tickets`
                data.price_evaluation_min = Number(localStorage.getItem("price_evaluation_min"))
                data.price_evaluation_max = Number(localStorage.getItem("price_evaluation_max"))
                data.producer = producer
                const { data: res } = await axiosJWT.post(url, { ...data, postal_code: data.postal_code.replace(/-/g, ''), phone: data.phone.replace(/ /g, '') })
                localStorage.removeItem("price_evaluation_min")
                localStorage.removeItem("price_evaluation_max")
                navigate("/my_tickets")
                console.log(res.message)
            } catch (error) {
                if (error.response.data !== undefined) {
                    let errorMessage = "Nie udało się utworzyć zgłoszenia: "
                    for (let key in error.response.data) {
                        errorMessage += key + ': ' + JSON.stringify(error.response.data[key]) + ', '
                    }
                    setError(errorMessage)
                }
            }
        }
    }

    return (
        <div>
            <ServiceNavbar />
            <Container>
                <h1 style={{ margin: '20px 0 20px 0' }}>Zgłoszenie serwisowe</h1>
                <Card className="mt-2">
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12} lg={7}>
                                    <Form.Label className="mt-2">Producent</Form.Label>
                                    {producerData}
                                    <Form.Label className="mt-2">Model</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Model twojego urządzenia"
                                        name="model"
                                        onChange={handleChange}
                                        value={data.model}
                                        isInvalid={errors.model}
                                        isValid={((errors.model === undefined) ? false : !errors.model) && data.model !== ""}
                                    />
                                    <Form.Label className="mt-2">Opis usterki</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        type="text"
                                        placeholder="Opisz tutaj swój problem i to, w jakich okolicznościach występuje.
Jeżeli doszło do uszkodzenia mechanicznego (upadek, zalanie itp.) także nas o tym poinformuj."
                                        name="description"
                                        onChange={handleChange}
                                        value={data.description}
                                    />
                                    <Form.Label className="mt-2">Telefon</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Telefon"
                                        name="phone"
                                        onChange={handleChange}
                                        value={data.phone}
                                        isInvalid={errors.phone}
                                        isValid={(errors.phone === undefined) ? false : !errors.phone}
                                        required
                                    />
                                    <Form.Label className="mt-2">Zadania serwisowe</Form.Label>
                                    {taskData}
                                </Col>

                                <Col md={12} lg={5}>
                                    <Form.Label className="mt-2">Dostawa</Form.Label>
                                    <Form.Check type="radio" name="delivery" id="delivery-0" value="0" label="Dostarczę osobiście" onChange={handleDelivery} defaultChecked={true} />
                                    <Form.Check type="radio" name="delivery" id="delivery-1" value="1" label="Kurier" onChange={handleDelivery} />
                                    <fieldset disabled={data.delivery === "0"}>
                                        <Form.Label className="mt-2">Dane do dostawy</Form.Label>
                                        <br />
                                        <Form.Label className="mt-2">Imię</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Imię"
                                            name="first_name"
                                            onChange={handleChange}
                                            value={data.first_name}
                                            isInvalid={errors.first_name}
                                            isValid={(errors.first_name === undefined) ? false : !errors.first_name}
                                            required={data.delivery === "1"}
                                        />
                                        <Form.Label className="mt-2">Nazwisko</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nazwisko"
                                            name="last_name"
                                            onChange={handleChange}
                                            value={data.last_name}
                                            isInvalid={errors.last_name}
                                            isValid={(errors.last_name === undefined) ? false : !errors.last_name}
                                            required={data.delivery === "1"}
                                        />
                                        <Form.Label className="mt-2">Ulica</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Ulica"
                                            name="street"
                                            onChange={handleChange}
                                            value={data.street}
                                            isInvalid={errors.street}
                                            isValid={(errors.street === undefined) ? false : !errors.street}
                                            required={data.delivery === "1"}
                                        />
                                        <Form.Label className="mt-2">Numer budynku</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Numer budynku"
                                            name="building_number"
                                            onChange={handleChange}
                                            value={data.building_number}
                                            isInvalid={errors.building_number}
                                            isValid={(errors.building_number === undefined) ? false : !errors.building_number}
                                            required={data.delivery === "1"}
                                        />
                                        <Form.Label className="mt-2">Numer mieszkania</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Numer mieszkania"
                                            name="apartment_number"
                                            onChange={handleChange}
                                            value={data.apartment_number}
                                            isInvalid={errors.apartment_number}
                                            isValid={((errors.apartment_number === undefined) ? false : !errors.apartment_number) && data.apartment_number !== ""}
                                        />
                                        <Form.Label className="mt-2">Kod pocztowy</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Kod pocztowy"
                                            name="postal_code"
                                            onChange={handleChange}
                                            value={data.postal_code}
                                            isInvalid={errors.postal_code}
                                            isValid={(errors.postal_code === undefined) ? false : !errors.postal_code}
                                            required={data.delivery === "1"}
                                        />
                                        <Form.Label className="mt-2">Miejscowość</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Miejscowość"
                                            name="city"
                                            onChange={handleChange}
                                            value={data.city}
                                            isInvalid={errors.city}
                                            isValid={(errors.city === undefined) ? false : !errors.city}
                                            required={data.delivery === "1"}
                                        />
                                    </fieldset>
                                </Col>
                            </Row>
                            <div className="d-grid gap-2">
                                {error && <Alert className="mt-2" variant="danger">{error}</Alert>}
                                {evaluationData}
                                <Button variant="primary" className="mt-3" type="submit" disabled={dataValid()} size="lg">
                                    Wyślij
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default TicketForm