import { useState } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css";
import { Navbar, Container, Nav, Card, Button, Form, Alert } from "react-bootstrap";
import ServiceNavbar from "../Utils/service_navbar";

const LoginForm = () => {
    const [data, setData] = useState({
        username: "",
        password: ""
    })

    const [error, setError] = useState("")
    const navigate = useNavigate()

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const url = `${process.env.REACT_APP_API_URL}/api/login`
            const { data: res } = await axios.post(url, data)
            if(res.access && res.refresh) {
                localStorage.setItem("access", res.access)
                localStorage.setItem("refresh", res.refresh)
            }
            console.log(res.message)
            navigate("/")
        } catch (error) {
            console.log(error.response.status)
            if (error.response) {
                if (error.response.status == 401) {
                    setError("Nieprawidłowe dane logowania!")
                }
                else {
                    setError("Logowanie nie powiodło się")
                }
            }
        }
    }
    return (
        <div>
            <ServiceNavbar />
            <Container>
                <h1 style={{ margin: '20px 0 20px 0' }}>Zaloguj się</h1>
                <Card className="mt-2">
                    <Card.Body>
                        <Card.Title>Chcesz utworzyć nowe zgłoszenie lub sprawdzić status realizacji zgłoszeń w toku? Musisz się zalogować.</Card.Title>
                        <Form onSubmit={handleSubmit}>
                            <Form.Label className="mt-2">Nazwa użytkownika</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nazwa użytkownika"
                                name="username"
                                onChange={handleChange}
                                value={data.username}
                                required
                            />
                            <Form.Label className="mt-2">Hasło</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Hasło"
                                name="password"
                                onChange={handleChange}
                                value={data.password}
                                required
                            />
                            <Button variant="primary" className="mt-3" type="submit">
                                Zaloguj się
                            </Button>
                        </Form>
                        {error && <Alert className="mt-2" variant="danger">{error}</Alert>}
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Body>
                        <Card.Title>Nie masz konta?</Card.Title>
                        <Card.Text>
                            Aby skorzystać z usług naszego serwisu, musisz się najpierw zarejestrować.
                            <Link className="d-block" to="/register">
                                <Button className="mt-2">
                                    Przejdź do rejestracji
                                </Button>
                            </Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default LoginForm