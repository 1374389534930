import { Route, Routes/*, Navigate*/ } from "react-router-dom"
import Main from "./components/Main"
import RegistrationForm from "./components/Forms/registration_form";
import LoginForm from "./components/Forms/login_form";
import TicketForm from "./components/Forms/ticket_form";
import MyTickets from "./components/Main/my_tickets";
import MyData from "./components/Main/my_data";
import jwt_decode from "jwt-decode";
import UnassignedTickets from "./components/Main/unassigned_tickets";

function App() {
    let is_staff = false;
    let is_admin = false;
    const user = localStorage.getItem("access")
    if (user) {
        const decodedAccessToken = jwt_decode(localStorage.getItem("access"))
        is_staff = decodedAccessToken.is_staff
        is_admin = decodedAccessToken.is_admin
    }
    const is_customer = !(is_staff || is_admin)
    
    // {user && <Route path="/" exact element={<Main />} />}
    return (
        <Routes>
            <Route path="/" exact element={<Main />} />
            <Route path="/register" exact element={<RegistrationForm />} />
            <Route path="/my_tickets" exact element={<MyTickets />} />
            {is_customer && <Route path="/new_ticket" exact element={<TicketForm />} />}
            {is_staff && <Route path="/unassigned_tickets" exact element={<UnassignedTickets />} />}
            {user && <Route path="/my_data" exact element={<MyData />} />}
            <Route path="/login" exact element={<LoginForm />} />
        </Routes>
    )
}

export default App