import { useState, useEffect } from "react";
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css";
import { Container, Card, Button, Row, Col, Form, Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ServiceNavbar from '../Utils/service_navbar';
import generateAlert from "../Utils/alert";

import { Mutex } from 'async-mutex';
import axiosRefreshToken from "../../functions/axiosRefreshToken"

const MyData = () => {

    const navigate = useNavigate()
    const mutex = new Mutex();

    const axiosJWT = axios.create({
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("access")}`
        }
    })
    axiosJWT.interceptors.request.use(async function (config) {
        const release = await mutex.acquire()
        try {
            return axiosRefreshToken(config, navigate)
        }
        finally {
            release()
        }
    }, function (error) {
        return Promise.reject(error);
    })

    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
    })
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
    })
    const [passwords, setPasswords] = useState({
        current_password: "",
        password: "",
        confirm_password: "",
    })
    const [error, setError] = useState(false)
    const [errors, setErrors] = useState({})
    const [dataAlert, setDataAlert] = useState("")
    const [passwordAlert, setPasswordAlert] = useState("")

    const validateField = (name, value) => {
        let validators = {
            first_name: /^[A-ZĄĘŚĆŻŹÓŁŃ][a-ząęśćżźółń]+$/,
            last_name: /^[A-ZĄĘŚĆŻŹÓŁŃ][A-ZĄĘŚĆŻŹÓŁŃa-ząęśćżźółń\-. ]+$/,
            password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
        }
        if (validators[name]) {
            return validators[name].test(value)
        }
        else if (name === "confirm_password") {
            return !errors.password && data.password === value
        }
    }

    const getUser = () => {
        axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/user`)
            .then(res => {
                setUser(res.data)
                setData(res.data)
            })
    }

    const updateDetails = async (e) => {
        e.preventDefault()
        try {
            const { data: res } = await axiosJWT.patch(`${process.env.REACT_APP_API_URL}/api/user`, data)
            setUser({ ...user, first_name: res.first_name, last_name: res.last_name })
            setError(false)
            setDataAlert(generateAlert("Dane zostały zmienione!", "success"))
        }
        catch (error) {
            if (error.response.data !== undefined) {
                let errorMessage = "Nie udało się zmodyfikować danych: "
                for (let key in error.response.data) {
                    errorMessage += key + ': ' + JSON.stringify(error.response.data[key]) + ', '
                }
                setError(errorMessage)
            }
        }
    }

    const updatePassword = async (e) => {
        e.preventDefault()
        try {
            const { data: res } = await axiosJWT.patch(`${process.env.REACT_APP_API_URL}/api/password`, passwords)
            setError(false)
            setPasswordAlert(generateAlert("Hasło zostało zmienione!", "success"))
        }
        catch (error) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    setPasswordAlert(generateAlert(error.response.data.message, "danger"))
                }
                else {
                    let errorMessage = "Nie udało się zmienić hasła: "
                    for (let key in error.response.data) {
                        errorMessage += key + ': ' + JSON.stringify(error.response.data[key]) + ', '
                    }
                    setPasswordAlert(generateAlert(errorMessage, "danger"))
                }
            }
        }
        finally {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            })
        }
    }

    const passwordValid = () => {
        return errors.password || errors.confirm_password
    }

    const userValid = () => {
        return errors.first_name || errors.last_name
    }

    const handleChange = ({ currentTarget: input }) => {
        setErrors({ ...errors, [input.name]: !validateField(input.name, input.value) })
        setData({ ...data, [input.name]: input.value })
    }

    const handlePasswordChange = ({ currentTarget: input }) => {
        setErrors({ ...errors, [input.name]: !validateField(input.name, input.value) })
        setPasswords({ ...passwords, [input.name]: input.value })
    }

    const handlePassword = ({ currentTarget: input }) => {
        handleChange({ currentTarget: input })
        setPasswordAlert("")
    }

    const handleCurrentPassword = ({ currentTarget: input }) => {
        setPasswords({ ...passwords, [input.name]: input.value })
    }

    useEffect(() => {
        getUser()
    }, []);

    return (
        <div>
            <ServiceNavbar />
            <Container>
                <h1 style={{ margin: '20px 0 20px 0' }}>Moje dane</h1>
                <h4>{user.first_name} {user.last_name} ({user.username})</h4>
                <h6>{user.email}</h6>
                <Card className="mt-2">
                    <Card.Body>
                        <Card.Title>Zmiana danych</Card.Title>
                        <Form onSubmit={updateDetails}>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Form.Label className="mt-2">Imię</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Imię"
                                        name="first_name"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        value={data.first_name}
                                        required
                                        isInvalid={errors.first_name}
                                        isValid={(errors.first_name === undefined) ? false : !errors.first_name}
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <Form.Label className="mt-2">Nazwisko</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nazwisko"
                                        name="last_name"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        value={data.last_name}
                                        required
                                        isInvalid={errors.last_name}
                                        isValid={(errors.last_name === undefined) ? false : !errors.last_name}
                                    />
                                </Col>
                            </Row>
                            <Alert variant="info" className="mt-2">
                                Imię i nazwisko muszą zaczynać się wielką literą. Nazwisko może zawierać myślniki, spacje i kropki.
                            </Alert>
                            <div className="d-grid gap-2">
                                <Button variant="primary" className="mt-0" type="submit" disabled={userValid()}>
                                    Zmień dane
                                </Button>
                                {dataAlert}
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Body>
                        <Card.Title>Zmiana hasła</Card.Title>
                        <Form onSubmit={updatePassword}>
                            <Row>
                                <Col>
                                    <Form.Label className="mt-2">Obecne hasło</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Wprowadź swoje obecne hasło"
                                        name="current_password"
                                        onChange={handleCurrentPassword}
                                        onBlur={handleCurrentPassword}
                                        value={passwords.current_password}
                                        required
                                        isInvalid={passwords.current_password === ""}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Form.Label className="mt-2">Nowe hasło</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Nowe hasło"
                                        name="password"
                                        onChange={handlePasswordChange}
                                        onBlur={handlePassword}
                                        value={passwords.password}
                                        required
                                        isInvalid={errors.password}
                                        isValid={(errors.password === undefined) ? false : !errors.password}
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <Form.Label className="mt-2">Powtórz nowe hasło</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Powtórz nowe hasło"
                                        name="confirm_password"
                                        onChange={handlePasswordChange}
                                        onBlur={handlePasswordChange}
                                        value={passwords.confirm_password}
                                        required
                                        isInvalid={errors.confirm_password}
                                        isValid={(errors.confirm_password === undefined) ? false : !errors.confirm_password}
                                    />
                                </Col>
                            </Row>
                            {passwordAlert}
                            <Alert variant="info" className="mt-2">
                                Hasło musi liczyć przynajmniej 8 znaków oraz zawierać przynajmniej jedną małą literę, dużą literę i cyfrę.
                            </Alert>
                            <div className="d-grid gap-2">
                                <Button variant="primary" className="mt-0" type="submit" disabled={(passwordValid() || passwords.current_password === "")}>
                                    Zmień hasło
                                </Button>
                            </div>
                        </Form>
                        {error && <Alert className="mt-2" variant="warning">{error}</Alert>}
                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default MyData