import axios from "axios";

function refreshToken() {
    const refreshToken = localStorage.getItem("refresh")
    const url = `${process.env.REACT_APP_API_URL}/api/login/refresh`
    const request = axios.post(url, { refresh: refreshToken })
    request.then(res => {
            if (res.status === 200) {
                localStorage.setItem("access", res.data.access)
            }
            else {
                localStorage.removeItem("access")
                localStorage.removeItem("refresh")
            }
        })
    return request
}

export default refreshToken