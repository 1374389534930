import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link, Navigate, useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css";
import { Container, Card, Button, Alert, Modal, Table } from "react-bootstrap";
import ServiceNavbar from "../Utils/service_navbar";
import UserCard from "../Cards/user_card";
import generateTasks from "../Lists/generate_tasks";

import {Mutex} from 'async-mutex';
import axiosRefreshToken from "../../functions/axiosRefreshToken"

const UnassignedTickets = () => {
    const navigate = useNavigate()
    const mutex = new Mutex();

    const axiosJWT = axios.create({
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("access")}`
        }
    })
    axiosJWT.interceptors.request.use(async function (config) {
        const release = await mutex.acquire()
        try {
            return axiosRefreshToken(config, navigate)
        }
        finally {
            release()
        }
    }, function (error) {
        return Promise.reject(error);
    })

    useEffect(() => {
        getTickets()
    }, []);

    const [error, setError] = useState("")

    const [ticketData, setTicketData] = useState()
    const [showDetails, setShowDetails] = useState(false)
    const [details, setDetails] = useState({ id: null })
    const [baseInfo, setBaseInfo] = useState({ id: null })
    const [statusTable, setStatusTable] = useState()
    const [taskList, setTaskList] = useState()
    const [deleteText, setDeleteText] = useState("Usuń")
    const [confirm, setConfirm] = useState(false)

    const handleClose = () => {
        setShowDetails(false)
        setDeleteText("Usuń")
        setConfirm(false)
    }

    const handleShow = (cardInfo) => {
        setBaseInfo(cardInfo)
        loadTicketDetails(cardInfo.ticket_id)
        loadStatusList(cardInfo.ticket_id)
        setShowDetails(true)
    };

    const handleAssign = async (e, ticket_id) => {
        e.preventDefault()
        try {
            const url = `${process.env.REACT_APP_API_URL}/api/assign_ticket/${ticket_id}`
            await axiosJWT.patch(url, {})
            handleClose()
            navigate('/my_tickets')
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message)
            }
        }
    }

    const getTickets = () => {
        axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/unassigned_tickets`).then(res => {
            const tickets = res.data
            setTicketData(generateList(tickets))
        })
    }

    const loadStatusList = (id) => {
        axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/ticket_status/${id}`).then(res => {
            const statuses = res.data
            setStatusTable(generateTable(statuses))
        })
    }

    const loadTicketDetails = (id) => {
        axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/ticket_details/${id}`).then(res => {
            const ticketDetails = res.data
            setDetails(ticketDetails)
            const tasks = res.data.tasks
            if (tasks.length > 0) {
                setTaskList(generateTasks(tasks))
            }
            else {
                setTaskList()
            }
        })
    }

    const prettyDateTime = (dateString) => {
        let date = new Date(dateString).toLocaleString()
        return date
    }

    // Generowanie listy zgłoszeń serwisowych
    // <> i </> są potrzebne żeby wygenerować listę, a nie jeden komponent
    const generateList = (ticketList) => {
        if (ticketList.length === 0) {
            return (
                <Alert variant="info" className="mt-2">Brak zgłoszeń</Alert>
            )
        }
        else return (
            <>
                {ticketList.map(ticket => (
                    <UserCard
                        key={ticket.id}
                        ticket_id={ticket.id}
                        date_add={ticket.date}
                        status={ticket.last_status}
                        producer={ticket.producer}
                        model={ticket.model}
                        final_price={ticket.final_price}
                        on_click={handleShow}
                    />
                ))}
            </>
        )
    }

    const generateTable = (statusList) => {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Data</th>
                        <th>Komentarz serwisanta</th>
                    </tr>
                </thead>
                <tbody>
                    {statusList.map(ticket_status => (
                        <tr key={ticket_status.id + " " + ticket_status.date}>
                            <td>{ticket_status.name}</td>
                            <td>
                                {prettyDateTime(ticket_status.date)}
                            </td>
                            <td>{ticket_status.technician_response}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    const generateDescription = (description) => {
        if(description) {
            return (
                <Card>
                    <Card.Header>Opis usterki</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {description}
                        </Card.Text>
                    </Card.Body>
                </Card>
            )
        } else {
            return <br/>
        }
    }

    const handleDelete = async () => {
        if(confirm) {
            const url = `${process.env.REACT_APP_API_URL}/api/del_ticket/${baseInfo.ticket_id}`
            await axiosJWT.delete(url)
            getTickets()
            handleClose()
        }
        else {
            setDeleteText("Potwierdź usunięcie")
            setConfirm(true)
        }
    }

    return (
        <div>
            <ServiceNavbar />
            <Container>
                <h1 style={{ margin: '20px 0 20px 0' }}>Oczekujące zgłoszenia</h1>
                {ticketData}
            </Container>
            <Modal
                size="lg"
                show={showDetails} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Szczegóły zgłoszenia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fw-light lh-sm">
                        Data utworzenia: {baseInfo.date_add}
                        <br />
                        Numer: {baseInfo.ticket_id}
                        <br />
                        Urządzenie: {baseInfo.producer} {baseInfo.model}
                        <br />
                        {details.price_evaluation_max && details.price_evaluation_max != 0 ? `Wstępna wycena czynności serwisowych: ${Number(details.price_evaluation_min)}-${Number(details.price_evaluation_max)} zł` : ""}
                    </p>
                    {statusTable}
                    {generateDescription(details.description)}
                    <br />
                    {taskList}
                    <br />
                    {baseInfo.final_price ? `Do zapłaty: ${Number(baseInfo.final_price)} zł` : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(e) => handleAssign(e, baseInfo.ticket_id)}>
                        Przyjmij
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        {deleteText}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UnassignedTickets