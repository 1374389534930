import { useState } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css";
import { Container, Card, Button, Form, Alert, Row, Col } from "react-bootstrap";
import generateAlert from "../Utils/alert";
import ServiceNavbar from "../Utils/service_navbar";

const RegistrationForm = () => {
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        confirm_password: ""
    })

    const [error, setError] = useState("")
    const [errors, setErrors] = useState("")
    const navigate = useNavigate()
    const [alerts, setAlerts] = useState({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        confirm_password: ""
    })

    const validateField = (name, value) => {
        let validators = {
            first_name: /^[A-ZĄĘŚĆŻŹÓŁŃ][a-ząęśćżźółń]+$/,
            last_name: /^[A-ZĄĘŚĆŻŹÓŁŃ][A-ZĄĘŚĆŻŹÓŁŃa-ząęśćżźółń\-. ]+$/,
            username: /^[A-Za-z][A-Za-z0-9-._ ]+$/,
            email: /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i,
            password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
        }
        if (validators[name]) {
            return validators[name].test(value)
        }
        else if (name === "confirm_password") {
            return !errors.password && data.password === value
        }
    }

    const emailExists = async (email_address) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/email_exists/${email_address}`)
            .then(res => {
                if (res.data.exists) {
                    setErrors({ ...errors, email: true })
                    setAlerts({ ...alerts, email: generateAlert("Ten adres e-mail jest już zarejestrowany.") })
                }
                else {
                    setErrors({ ...errors, email: false })
                    setAlerts({ ...alerts, email: "" })
                }
            })
    }

    const usernameExists = async (name) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/username_exists/${name}`)
            .then(res => {
                if (res.data.exists) {
                    setErrors({ ...errors, username: true })
                    setAlerts({ ...alerts, username: generateAlert("Użytkownik o podanej nazwie jest już zarejestrowany.") })
                }
                else {
                    setErrors({ ...errors, username: false })
                    setAlerts({ ...alerts, username: "" })
                }
            })
    }

    const checkEmailExists = ({ currentTarget: input }) => {
        if (!errors.email) {
            handleChange({ currentTarget: input })
            if (input.value !== "") {
                emailExists(input.value)
            }
        }
    }

    const checkUsernameExists = ({ currentTarget: input }) => {
        if (!errors.username) {
            handleChange({ currentTarget: input })
            if (input.value !== "") {
                usernameExists(input.value)
            }
        }
    }

    const handlePassword = ({ currentTarget: input }) => {
        handleChange({ currentTarget: input })
        setAlerts({ ...alerts, password: "" })
    }

    const dataValid = () => {
        return errors.first_name || errors.last_name || errors.username || errors.email || errors.password || errors.confirm_password
    }

    const handleChange = ({ currentTarget: input }) => {
        setErrors({ ...errors, [input.name]: !validateField(input.name, input.value) })
        setData({ ...data, [input.name]: input.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        usernameExists(data.username)
        emailExists(data.email)
        if (dataValid) {
            try {
                const url = `${process.env.REACT_APP_API_URL}/api/register`
                const { data: res } = await axios.post(url, data)
                navigate("/login")
            } catch (error) {
                if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    if (error.response.data.password[0] !== undefined) {
                        setAlerts({ ...alerts, password: generateAlert(error.response.data.password[0]) })
                    }

                    setError(`Rejestracja nie powiodła się! Upewnij się, że formularz jest wypełniony prawidłowo. (Błąd serwera: ${error.response.status})`)
                }
            }
        }
    }

    return (
        <div>
            <ServiceNavbar />
            <Container>
                <h1 style={{ margin: '20px 0 20px 0' }}>Zarejestruj się</h1>
                <Card className="mt-2">
                    <Card.Body>
                        <Card.Title>Twój laptop lub komputer potrzebuje naszej pomocy? Aby skorzystać z usług naszego serwisu, zarejestruj się już teraz!</Card.Title>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Form.Label className="mt-2">Imię</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Imię"
                                        name="first_name"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        value={data.first_name}
                                        required
                                        isInvalid={errors.first_name}
                                        isValid={(errors.first_name === undefined) ? false : !errors.first_name}
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <Form.Label className="mt-2">Nazwisko</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nazwisko"
                                        name="last_name"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        value={data.last_name}
                                        required
                                        isInvalid={errors.last_name}
                                        isValid={(errors.last_name === undefined) ? false : !errors.last_name}
                                    />
                                </Col>
                            </Row>
                            <Alert variant="info" className="mt-2">
                                Imię i nazwisko muszą zaczynać się wielką literą. Nazwisko może zawierać myślniki, spacje i kropki.
                            </Alert>
                            <Form.Label className="mt-2">Nazwa użytkownika</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nazwa użytkownika"
                                name="username"
                                onChange={handleChange}
                                onBlur={checkUsernameExists}
                                value={data.username}
                                required
                                isInvalid={errors.username}
                                isValid={(errors.username === undefined) ? false : !errors.username}
                            />
                            <Alert variant="info" className="mt-2">
                                Nazwa użytkownika może zawierać duże i małe litery (bez polskich znaków), cyfry oraz znaki kropki, myślnika i podkreślenia.
                            </Alert>
                            {alerts.username}
                            <Form.Label className="mt-2">Adres e-mail</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Adres e-mail"
                                name="email"
                                onChange={handleChange}
                                onBlur={checkEmailExists}
                                value={data.email}
                                required
                                isInvalid={errors.email}
                                isValid={(errors.email === undefined) ? false : !errors.email}
                            />
                            {alerts.email}
                            <Row>
                                <Col sm={12} md={6}>
                                    <Form.Label className="mt-2">Hasło</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Hasło"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handlePassword}
                                        value={data.password}
                                        required
                                        isInvalid={errors.password}
                                        isValid={(errors.password === undefined) ? false : !errors.password}
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <Form.Label className="mt-2">Powtórz hasło</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Powtórz hasło"
                                        name="confirm_password"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        value={data.confirm_password}
                                        required
                                        isInvalid={errors.confirm_password}
                                        isValid={(errors.confirm_password === undefined) ? false : !errors.confirm_password}
                                    />
                                </Col>
                            </Row>
                            <Alert variant="info" className="mt-2">
                                Hasło musi liczyć przynajmniej 8 znaków oraz zawierać przynajmniej jedną małą literę, dużą literę i cyfrę.
                            </Alert>
                            {alerts.password}
                            <Button variant="primary" className="mt-3" type="submit" disabled={dataValid()}>
                                Zarejestruj się
                            </Button>
                        </Form>
                        {error && <Alert className="mt-2" variant="warning">{error}</Alert>}
                    </Card.Body>
                </Card>
                <Card className="mt-2">
                    <Card.Body>
                        <Card.Title>Masz już konto?</Card.Title>
                        <Card.Text>
                            Kliknij tutaj, jeśli już masz konto i chcesz przejść do serwisu.
                            <Link className="d-block" to="/login">
                                <Button className="mt-2">
                                    Przejdź do logowania
                                </Button>
                            </Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default RegistrationForm