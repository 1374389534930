import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Card } from "react-bootstrap";

class TicketAddress extends Component {
    constructor(props) {
        super(props)
        this.address = props.address
    }

    render() {
        return (
            <Card className="mt-2">
                <Card.Header>Adres klienta</Card.Header>
                <Card.Body>
                    {this.address.first_name} {this.address.last_name}
                    <br />
                    {this.address.street} {this.address.building_number}{this.address.apartment_number ? `/${this.address.apartment_number}` : ""}
                    <br />
                    {this.address.postal_code.slice(0, 2)}-{this.address.postal_code.slice(2, 5)} {this.address.city}
                </Card.Body>
            </Card>
        )
    }
}

export default TicketAddress