import refreshToken from './refreshToken'
import jwt_decode from "jwt-decode"

async function axiosRefreshToken(config, navigate) {
    try {
        let token = config.headers.Authorization.split(' ')[1]
        if (parseInt(jwt_decode(token).exp) <= parseInt((new Date().getTime() / 1000))) {
            let res = await refreshToken()
            if (res.status !== 200) {
                localStorage.removeItem("access")
                localStorage.removeItem("refresh")
                navigate('/login')
            }
            config.headers.Authorization = `Bearer ${localStorage.getItem("access")}`
        }
        return config
    }
    catch (e) {
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        navigate('/login')
    }
}

export default axiosRefreshToken