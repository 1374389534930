import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Card } from "react-bootstrap";

class TicketWorker extends Component {
    constructor(props) {
        super(props)
        this.worker = props.worker
    }

    render() {
        return (
            <Card className="mt-2">
                <Card.Header>Serwisant</Card.Header>
                <Card.Body>
                    {this.worker.first_name} {this.worker.last_name}
                    <br />
                    E-mail: {this.worker.email}
                </Card.Body>
            </Card>
        )
    }
}

export default TicketWorker