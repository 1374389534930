import "bootstrap/dist/css/bootstrap.css";
import { Container, Row, Col } from "react-bootstrap";
import ServiceNavbar from '../Utils/service_navbar';
import Carousel from 'react-bootstrap/Carousel';
import Laptop from './laptop.jpeg'
import Inside from './inside.jpeg'
import Fan from './fan.jpeg'

const Main = () => {

    return (
        <div>
            <ServiceNavbar />
            <Container>
                <h1 style={{ margin: '20px 0 20px 0' }}>Serwis komputerowy "Gucio"</h1>
                <Row>
                    <Col lg={8} md={12}>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Laptop}
                                    alt="First slide"
                                />
                                <Carousel.Caption>
                                    <h3>Profesjonalna obsługa</h3>
                                    <p>Mamy 30 lat doświadczenia w serwisowaniu komputerów.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Inside}
                                    alt="Second slide"
                                />

                                <Carousel.Caption>
                                    <h3>Szybka realizacja</h3>
                                    <p>Zanim się obejrzysz, Twój sprzęt będzie naprawiony.</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Fan}
                                    alt="Third slide"
                                />

                                <Carousel.Caption>
                                    <h3>Przystępne ceny</h3>
                                    <p>
                                        Nie musisz kupować nowego urządzenia, kiedy stare można naprawić.
                                    </p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col lg={4} md={12}>
                        <h3>Gdzie nas znaleźć?</h3>
                        <iframe width="100%" height="350" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                            src="https://www.openstreetmap.org/export/embed.html?bbox=22.50535368919373%2C51.22425745622608%2C22.5098329782486%2C51.225940582290626&amp;layer=mapnik&amp;marker=51.22509902695218%2C22.50759333372116"
                            style={{ border: '1px solid black' }}>
                        </iframe>
                        <br />
                        <small>
                            <a href="https://www.openstreetmap.org/?mlat=51.22510&amp;mlon=22.50759#map=19/51.22510/22.50759&amp;layers=N">
                                Wyświetl większą mapę
                            </a>
                        </small>
                        <br />
                        Serwis komputerowy "Gucio"<br />
                        ul. Perłowa 28<br />
                        20-399 Lublin<br />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Main