import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css";
import { Container, Card, Button, Form, Alert, Modal, Table, Row, Col } from "react-bootstrap";

import ServiceNavbar from "../Utils/service_navbar";
import UserCard from "../Cards/user_card";
import generateTasks from "../Lists/generate_tasks";
import jwt_decode from "jwt-decode";
import TicketClient from "../Cards/ticket_client";
import TicketWorker from "../Cards/ticket_worker";
import TicketAddress from "../Cards/ticket_address";

import { Mutex } from 'async-mutex';
import axiosRefreshToken from "../../functions/axiosRefreshToken"

const MyTickets = () => {
    const navigate = useNavigate()
    const mutex = new Mutex();

    const axiosJWT = axios.create({
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("access")}`
        }
    })
    axiosJWT.interceptors.request.use(async function (config) {
        const release = await mutex.acquire()
        try {
            return axiosRefreshToken(config, navigate)
        }
        finally {
            release()
        }
    }, function (error) {
        return Promise.reject(error);
    })

    const [dateFrom, setDateFrom] = useState("")
    const [dateTo, setDateTo] = useState("")

    useEffect(() => {
        let today = new Date()
        let from = new Date(new Date().setDate(today.getDate() - 30)).toISOString().slice(0, 10)
        let to = today.toISOString().slice(0, 10)
        setDateFrom(from)
        setDateTo(to)
        getTickets()
        getStatuses()
        localStorage.setItem("ticket_id", "")
    }, []);

    const [ticketData, setTicketData] = useState()
    const [showDetails, setShowDetails] = useState(false)
    const [details, setDetails] = useState({ id: null })
    const [baseInfo, setBaseInfo] = useState({ id: null })
    const [statusTable, setStatusTable] = useState()
    const [taskList, setTaskList] = useState()
    const [userIsStaff, setUserIsStaff] = useState(jwt_decode(localStorage.getItem("access")).is_staff)
    const [statusData, setStatusData] = useState()
    const [formData, setFormData] = useState({
        status: "",
        technician_response: "",
        ticket: ""
    })
    const [finalPrice, setFinalPrice] = useState("")
    const [error, setError] = useState("")
    //const [checkedStatuses, setCheckedStatuses] = useState([])
    const [statusChecks, setStatusChecks] = useState()
    const [address, setAddress] = useState("")
    const [ticketUser, setTicketUser] = useState("")

    const bareTicketList = useRef([])
    var ticketListForEdit = []
    var checkedStatuses = []

    const handleClose = () => setShowDetails(false)

    const handleShow = (cardInfo) => {
        setBaseInfo(cardInfo)
        loadTicketDetails(cardInfo.ticket_id)
        loadStatusList(cardInfo.ticket_id)
        setShowDetails(true)
    };

    const getTickets = async () => {
        let today = new Date()
        let from = dateFrom
        let to = dateTo

        if (from === "") {
            from = new Date(new Date().setDate(today.getDate() - 30)).toISOString().slice(0, 10)
        }
        if (to === "") {
            to = today.toISOString().slice(0, 10)
        }

        axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/ticket_cards?from=${from}&to=${to}`).then(res => {
            bareTicketList.current = res.data
            ticketListForEdit = res.data
            setTicketData(generateList(ticketListForEdit))
        })
    }

    const loadStatusList = (id) => {
        axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/ticket_status/${id}`).then(res => {
            const statuses = res.data
            setStatusTable(generateTable(statuses))
        })
    }

    const generateTicketClientInfo = (client, phone) => {
        return (
            <TicketClient client={client} phone={phone} />
        )
    }

    const generateTicketWorkerInfo = (worker) => {
        return (
            <TicketWorker worker={worker} />
        )
    }

    const generateAddressInfo = (address) => {
        return (
            <TicketAddress address={address} />
        )
    }

    const loadTicketDetails = (id) => {
        setTicketUser("")
        setAddress("")
        axiosJWT.get(`${process.env.REACT_APP_API_URL}/api/ticket_details/${id}`).then(res => {
            const ticketDetails = res.data
            setDetails(ticketDetails)
            const tasks = res.data.tasks
            if (tasks.length > 0) {
                setTaskList(generateTasks(tasks))
            }
            else {
                setTaskList()
            }
            if ("client" in ticketDetails) {
                setTicketUser(generateTicketClientInfo(ticketDetails.client, ticketDetails.phone))
            }
            else if ("worker" in ticketDetails) {
                setTicketUser(generateTicketWorkerInfo(ticketDetails.worker))
            }
            if ("address" in ticketDetails) {
                setAddress(generateAddressInfo(ticketDetails.address))
            }
        })
    }

    const prettyDateTime = (dateString) => {
        let date = new Date(dateString).toLocaleString()
        return date
    }

    // Generowanie listy zgłoszeń serwisowych
    // <> i </> są potrzebne żeby wygenerować listę, a nie jeden komponent
    const generateList = (ticketList) => {
        if (ticketList.length === 0) {
            return (
                <Alert variant="info" className="mt-2">Brak zgłoszeń</Alert>
            )
        }
        else return (
            <>
                {ticketList.map(ticket => (
                    <UserCard
                        key={ticket.id}
                        ticket_id={ticket.id}
                        date_add={ticket.date}
                        status={ticket.last_status}
                        producer={ticket.producer}
                        model={ticket.model}
                        final_price={ticket.final_price ? ticket.final_price : ""}
                        on_click={handleShow}
                        is_staff={userIsStaff}
                    />
                ))}
            </>
        )
    }

    const generateTable = (statusList) => {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Data</th>
                        <th>Komentarz serwisanta</th>
                    </tr>
                </thead>
                <tbody>
                    {statusList.map(ticket_status => (
                        <tr key={ticket_status.id + " " + ticket_status.date}>
                            <td>{ticket_status.name}</td>
                            <td>
                                {prettyDateTime(ticket_status.date)}
                            </td>
                            <td>{ticket_status.technician_response}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    const generateDescription = (description) => {
        if (description) {
            return (
                <Card>
                    <Card.Header>Opis usterki</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {description}
                        </Card.Text>
                    </Card.Body>
                </Card>
            )
        } else {
            return <br />
        }
    }

    const getStatuses = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/statuses`)
            .then(res => {
                const statuses = res.data
                checkedStatuses = statuses.map(status => status.name)
                setStatusData(generateStatusSelect(statuses))
                setStatusChecks(generateStatusCheckboxes(statuses))
            })
    }

    const handleStatusSelect = ({ currentTarget: input }) => {
        formData.status = input.value
    }

    const generateStatusSelect = (statusData) => {
        return <Form.Select name="status" onChange={handleStatusSelect} required>
            <option value="">Wybierz status</option>
            {statusData.map(status => (
                <option key={status.id} value={status.id}>{status.name}</option>
            ))}
        </Form.Select>
    }

    const handleChange = ({ currentTarget: input }) => {
        setFormData({ ...formData, [input.name]: input.value })
    }

    const handlePriceChange = ({ currentTarget: input }) => {
        setFinalPrice(input.value)
    }

    const awaitSubmit = (e) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/api/ticket_status`
            formData.ticket = localStorage.getItem("ticket_id")
            const request = axiosJWT.post(url, formData)
            return request
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message)
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let res = await awaitSubmit(e)
        if (res.status === 201) {
            localStorage.setItem("ticket_id", "")
            handleClose()
            getTickets()
            setTicketData(generateList(ticketListForEdit))
        }
    }

    const generateTicketEditForm = (ticket_id) => {
        if (userIsStaff) {
            localStorage.setItem("ticket_id", ticket_id)
            return (
                <Form onSubmit={handleSubmit}>
                    <hr />
                    <h4>Aktualizacja statusu zgłoszenia</h4>
                    <Form.Label className="mt-2">Nowy status</Form.Label>
                    {statusData}
                    <Form.Label className="mt-2">Komentarz serwisanta</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        type="text"
                        placeholder="Przekaż użytkownikowi istotne informacje o jego zgłoszeniu serwisowym."
                        name="technician_response"
                        onChange={handleChange}
                        value={formData.technician_response}
                    />
                    <Button variant="primary" className="mt-3" type="submit">
                        Zaktualizuj status
                    </Button>
                </Form>)
        }
    }

    const handlePriceSubmit = async (e) => {
        e.preventDefault()
        try {
            const url = `${process.env.REACT_APP_API_URL}/api/ticket_update_price/${localStorage.getItem("ticket_id")}`
            const { data: res } = await axiosJWT.patch(url, { final_price: finalPrice })
            setFinalPrice("")
            handleClose()
            getTickets()
            setTicketData(generateList(ticketListForEdit))
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message)
            }
        }
    }

    const handleStatusCheckbox = ({ currentTarget: input }) => {
        if (input.checked) {
            checkedStatuses.push(input.value)
        } else {
            var index = checkedStatuses.indexOf(input.value);
            if (index !== -1) {
                checkedStatuses.splice(index, 1);
            }
        }
        let filteredTickets = bareTicketList.current.filter(function (ticket) {
            return checkedStatuses.includes(ticket.last_status)
        })
        filteredTickets = filteredTickets.filter(function (ticket) {
            return checkedStatuses.includes(ticket.last_status)
        })
        setTicketData(generateList(filteredTickets))
    }

    const generateFinalPriceForm = (ticket_final_price) => {
        if (userIsStaff) {
            return (
                <Form onSubmit={handlePriceSubmit}>
                    <hr />
                    <h4>Aktualizacja finalnej wyceny</h4>
                    <Form.Label className="mt-2">Finalna wycena</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Finalna wycena"
                        name="final_price"
                        onChange={handlePriceChange}
                        value={finalPrice ? finalPrice : ticket_final_price}
                        min={0}
                        required
                    />
                    <Button variant="primary" className="mt-3" type="submit">
                        Zaktualizuj wycenę
                    </Button>
                </Form>)
        }
    }

    const generateStatusCheckboxes = (statusData) => {
        return (
            <Form.Group className="row">
                {statusData.map(status => (
                    <Form.Check
                        className="col-sm-12 col-md-6 col-lg-4"
                        type="checkbox"
                        label={status.name}
                        id={status.name}
                        value={status.name}
                        key={status.id}
                        onChange={handleStatusCheckbox}
                        defaultChecked={true}
                    />
                ))}
            </Form.Group>
        )
    }

    const handleDateFromSelect = ({ currentTarget: input }) => {
        if(Date.parse(input.value)>Date.parse(dateTo)) {
            setDateFrom(dateTo)
        }
        else {
            setDateFrom(input.value)
        }
    }

    const handleDateToSelect = ({ currentTarget: input }) => {
        setDateTo(input.value)
    }

    const handleDateSubmit = (e) => {
        e.preventDefault()
        getTickets()
    }

    const generateDateForm = () => {
        return (
            <Form className="mb-3" onSubmit={handleDateSubmit}>
                <Row>
                    <Col sm={12} md={6}>
                        <Form.Label className="mb-0">Od: </Form.Label>
                        <Form.Control type="date" name='from' value={dateFrom} onChange={handleDateFromSelect} onBlur={handleDateFromSelect} className="mt-0" />
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Label className="mb-0">Do: </Form.Label>
                        <Form.Control type="date" name='to' value={dateTo} onChange={handleDateToSelect} className="mt-0" />
                    </Col>
                </Row>
                <div className="d-grid gap-2">
                    <Button variant="primary" className="mt-2" type="submit">
                        Pobierz zgłoszenia
                    </Button>
                </div>
            </Form>
        )
    }

    return (
        <div>
            <ServiceNavbar />
            <Container>
                <h1 style={{ margin: '20px 0 20px 0' }}>{userIsStaff ? `Przyjęte zgłoszenia` : `Moje zgłoszenia`}</h1>
                {generateDateForm()}
                {statusChecks}
                {ticketData}
            </Container>
            <Modal
                size="lg"
                show={showDetails} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Szczegóły zgłoszenia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fw-light lh-sm">
                        Data utworzenia: {baseInfo.date_add}
                        <br />
                        Numer: {baseInfo.ticket_id}
                        <br />
                        Urządzenie: {baseInfo.producer} {baseInfo.model}
                        <br />
                        {details.price_evaluation_max && details.price_evaluation_max != 0 ? `Wstępna wycena czynności serwisowych: ${Number(details.price_evaluation_min)}-${Number(details.price_evaluation_max)} zł` : ""}
                    </p>
                    {statusTable}
                    {generateDescription(details.description)}
                    <br />
                    {taskList}
                    {ticketUser}
                    {address}
                    <br />
                    {baseInfo.final_price ? `Do zapłaty: ${Number(baseInfo.final_price).toFixed(2).replace(".", ",")} zł` : ""}
                    {generateTicketEditForm(baseInfo.ticket_id)}
                    {generateFinalPriceForm(baseInfo.final_price)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MyTickets