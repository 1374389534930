import React, { Component, useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Navbar, Container, Nav, Card, Button, Form, Alert, NavDropdown, Image } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import Gucio from "./logo192.png";

class ServiceNavbar extends Component {
    render() {
        let loggedIn = localStorage.getItem("access"),
            navbarContents;

        const handleLogInOut = () => {
            if (loggedIn) {
                localStorage.removeItem("access");
                window.location.replace("/");
            }
            else {
                window.location.replace("/login");
            }
        };

        if (loggedIn) {
            const decodedAccessToken = jwt_decode(localStorage.getItem("access"));
            if (decodedAccessToken.is_staff) {
                const adminLink = `${process.env.REACT_APP_API_URL}/admin`;
                navbarContents = <Nav className="me-auto">
                    <Nav.Link href="/my_tickets">Przyjęte zgłoszenia</Nav.Link>
                    <Nav.Link href="/unassigned_tickets">Oczekujące zgłoszenia</Nav.Link>
                    <NavDropdown title={decodedAccessToken.username} id="basic-nav-dropdown">
                        <NavDropdown.Item href="/my_data">Moje dane</NavDropdown.Item>
                        <NavDropdown.Item href={adminLink}>Panel administratorski</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={handleLogInOut}>Wyloguj</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            } else {
                navbarContents = <Nav className="me-auto">
                    <Nav.Link href="/">O nas</Nav.Link>
                    <Nav.Link href="/new_ticket">Nowe zgłoszenie</Nav.Link>
                    <NavDropdown title={decodedAccessToken.username} id="basic-nav-dropdown">
                        <NavDropdown.Item href="/my_tickets">Moje zgłoszenia</NavDropdown.Item>
                        <NavDropdown.Item href="/my_data">Moje dane</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={handleLogInOut}>Wyloguj</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            }
        } else {
            navbarContents = <Nav className="me-auto">
                <Nav.Link href="/">O nas</Nav.Link>
                <Nav.Link href="/login">Nowe zgłoszenie</Nav.Link>
                <NavDropdown title="Moje konto" id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={handleLogInOut}>Zaloguj się</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        }

        return (
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <Image src={Gucio} width={64} style={{ paddingRight: 15 }}/>
                    <Navbar.Brand>Serwis Gucio &#128187;</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {navbarContents}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    };
}

export default ServiceNavbar;